/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

ion-header>ion-toolbar>ion-title {
    text-align: center !important;
}

#container {
    text-align: center;

    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;

    color: #8c8c8c;

    margin: 0;
}

#container a {
    text-decoration: none;
}

.modal-fullscreen {
    &::part(content) {
        width: 100%;
        height: 100% !important;
    }
}

.modal-fullscreen-xl {
    &::part(content) {
        width: 90%;
    }
}

.modal-fullscreen-lg {
    &::part(content) {
        width: 83.333333%;
    }
}

.modal-fullscreen-md {
    &::part(content) {
        width: 66.666666%;
    }
}

.modal-fullscreen-sm {
    &::part(content) {
        width: 33.33333%;
    }
}

.modal-fullscreen-xs {
    &::part(content) {
        width: 16.666666%;
    }
}

.ios .modal-fullscreen,
.modal-fullscreen-xl,
.modal-fullscreen-lg,
.model-fullscreen-md,
.modal-fullscreen-sm,
.modal-fullscreen-xs {
    .modal-wrapper {
        top: 26px;
    }
}

.clickable {
    cursor: pointer;
}